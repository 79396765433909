import React from 'react'
import { Link } from 'gatsby'
import { Navbar, Nav } from 'react-bootstrap'
import { websiteLinks } from '../staticData/websiteLinks'

export default () => (
  <Navbar bg="light" expand="lg">
    <Link to="/">
      <Navbar.Brand>guckmalyoga</Navbar.Brand>
    </Link>

    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto">
        {websiteLinks
          .filter((link) => link.inTopNav)
          .map(({ to, label }) => (
            <Nav.Link key={label} to={to} as={Link}>
              {label}
            </Nav.Link>
          ))}
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)
