import React, { useState, useEffect } from 'react'
import { Container, Alert } from 'react-bootstrap'
import Navigation from './navigation'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import queryString from 'query-string'

import Footer from './footer'
import './layout.scss'

const Layout = ({ children }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            topic
          }
        }
      }
    `
  )
  useEffect(() => {
    if (location) {
      const data = queryString.parse(window.location.search)
      if (data && data.success) {
        setIsSuccess(true)
      }
    }
  }, [])
  return (
    <>
      <Helmet>
        <script src="https://sibforms.com/forms/end-form/build/main.js"></script>
        <script src="https://www.google.com/recaptcha/api.js?hl=en"></script>
        <html lang="de" amp />
        <meta name="author" content={site.siteMetadata.author} />
        <meta name="topic" content={site.siteMetadata.topic} />
      </Helmet>
      <Container>
        {isSuccess && (
          <Alert className="mt-1" variant="success">
            Deine Anmeldung für den Newsletter von guckmalyoga war erfolgreich.
          </Alert>
        )}
        <Navigation />
        {children}
        <Footer />
      </Container>
    </>
  )
}

export default Layout
