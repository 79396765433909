export const websiteLinks = [
  {
    label: 'Startseite',
    to: '/',
    inTopNav: true,
  },
  {
    label: 'Posts',
    to: '/post',
    inTopNav: true,
  },
  {
    label: 'Feedback',
    to: '/feedback',
    inTopNav: true,
  },
  {
    label: 'Spenden',
    to: '/spenden',
    inTopNav: true,
  },
  {
    label: 'Über mich',
    to: '/about',
    inTopNav: true,
  },
  {
    label: 'Kontakt',
    to: '/contact',
    inTopNav: true,
  },
  {
    label: 'Impressum',
    to: '/impressum',
    inTopNav: false,
  },
  {
    label: 'Datenschutzerklärung',
    to: '/datenschutzerklärung',
    inTopNav: false,
  },
]
