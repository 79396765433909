import React from 'react'
import { Link } from 'gatsby'
import { websiteLinks } from '../staticData/websiteLinks'
import randomstring from 'randomstring'
import chunk from 'loadsh/chunk'
const cols = chunk(websiteLinks, 2)

const footer = () => {
  return (
    <div className="">
      <div className="footer flex-wrap d-flex justify-content-between">
        {cols.map((col) => {
          return (
            <ul key={randomstring.generate(7)} className="m-0 mr-2 ml-2">
              {col.map(({ to, label }) => (
                <li className="m-0" key={label}>
                  <Link to={to}>{label}</Link>
                </li>
              ))}
            </ul>
          )
        })}
      </div>
      <div className="d-flex justify-content-center pt-2">
        <hr />
        Made with &hearts; in Berlin
      </div>
    </div>
  )
}
export default footer
